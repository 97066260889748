<template>
  <div class="expenditureDetail">
    <Scroll class="region-scroll" ref="scroll">
      <div class="expenditureitems" v-for="(item, index) in list" :key="index">
        <div class="feeitem">
          <div style="flex:0.5">名称</div>
          <div class="itemspecial">{{ item.itemname }}</div>
        </div>
        <div class="feeitem">
          <div>单价</div>
          <div>{{ item.price + '/' + item.unit }}</div>
        </div>
        <div class="feeitem">
          <div>数量</div>
          <div>{{ item.quantity + item.unit }}</div>
        </div>
        <div class="feeitem">
          <div>总价</div>
          <div>{{ item.amt + '元' }}</div>
        </div>
      </div>
    </Scroll>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
import { outPatientFeeInfoDetail } from '@/network/service.js'
import { mapState } from 'vuex'
export default {
  name: 'expenditureDetail',
  data() {
    return {
      list: [],
    }
  },
  components: {
    Scroll,
  },
  mounted() {
    console.log(1)
    this.outPatientFeeInfoDetailPost()
  },
  computed: {
    ...mapState(['seq', 'cardList']),
  },
  methods: {
    async outPatientFeeInfoDetailPost() {
      console.log(1)
      let y = new Date().getFullYear()
      let startm = new Date().getMonth()
      let endm = new Date().getMonth() + 1
      startm = startm < 10 ? '0' + startm : startm
      endm = endm < 10 ? '0' + endm : endm
      let d = new Date().getDate()
      d = d < 10 ? '0' + d : d
      let startdate = y + '-' + startm + '-' + d
      let enddate = y + '-' + endm + '-' + d
      let res = await outPatientFeeInfoDetail({
        med_id: this.$store.state.med_id,
        card_no: this.$Base64.decode(this.cardList[0].idNo),
        card_type: 2,
        charge_id: this.$route.query.charge_id,
        start_date: startdate,
        end_date: enddate,
      })
      if (res.code == '0') {
        this.list = res.data.root.body.detail
        this.$nextTick(() => {
          this.$refs.scroll.refresh()
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.expenditureDetail {
  background-color: #f5f5f5f5;
  .region-scroll {
    height: 100vh;
    overflow: hidden;
    .expenditureitems {
      background-color: #fff;
      margin: 1rem 0rem;
      padding: 0.5rem 0.7rem;
      .feeitem {
        height: 2rem;
        display: flex;
        justify-content: space-between;
        .itemspecial {
          text-align: right;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-left: 3rem;
          flex: 0.5;
        }
      }
    }
  }
}
</style>
